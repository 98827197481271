<template>
  <div class="columns columns-override">
    <div class="column col-8 col-md-12 column-override">
      <h1>{{ $t("nordic_minds_search.intro") }}</h1>
    </div>
    <div class="column col-4 col-md-12 txt--center">
      <img
        src="../../assets/images/service/services_nm-search_header_illustration.png"
        class="intro__image"
      />
    </div>
    <div class="column col-12 column-override">
      <ul class="list--arrow-box">
        <li
          v-for="(text, index) in $t('nordic_minds_search.trouble')"
          :key="index"
          class="list__item"
        >
          {{ text }}
        </li>
      </ul>
    </div>

    <div
      class="column col-10 col-md-12 col-offset-1 col-md-offset-0 txt--center column-override"
    >
      <h3 class="h1">{{ $t("nordic_minds_search.statement_1_headline") }}</h3>
    </div>
    <div
      class="column col-8 col-md-12 col-offset-2 col-md-offset-0 txt--center column-override"
    >
      <div class="text">{{ $t("nordic_minds_search.statement_1_text") }}</div>
    </div>

    <div class="column col-12 col-last">
      <div class="list--boxed list--boxed-left-bottom">
        <h3 class="h4">{{ $t("nordic_minds_search.statement_2_headline") }}</h3>
        <ul class="list__item list--check">
          <li
            v-for="(text, idx) in $t('nordic_minds_search.statement_2_list')"
            :key="idx"
            class="list__item"
          >
            {{ text }}
          </li>
        </ul>
      </div>
    </div>

    <div class="column column--full-width-bg">
      <div
        class="column col-6 col-sm-12 col-md-10 col-lg-9 col-xl-8 txt--center"
      >
        <h4>{{ $t("nordic_minds_search.link_headline") }}</h4>
        <router-link class="button button--arrow-left" to="/contact">{{
          $t("navigation.letstalk")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NordicMindsSearch",
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    clickSubline: function(item) {
      if (this.selected == item) {
        this.selected = 0;
      } else {
        this.selected = item;
      }
    },
    toggleSubline: function(item, className) {
      if (this.selected == item) {
        return `${className}--visible`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.h1 {
  line-height: 1.4;
  margin-bottom: 1.6rem;
  margin-top: 5.5rem;
}

.column--full-width-bg {
  @include column--full-width-bg($color-primary-50);
}

.list--arrow-box {
  @include list--arrow-box;

  @media (min-width: $size-sm) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .list__item {
    &:nth-child(4) {
      @media (min-width: $size-lg) {
        margin-left: 17%;
      }
    }

    &:nth-child(5) {
      @media (min-width: $size-md) {
        margin-right: 17%;
      }
    }
  }

  li {
    p {
      margin: 0;
      text-align: center;
      width: 100%;
    }
  }
}

.list--boxed {
  @include list--boxed;
  @include list--boxed-left-bottom;

  justify-content: flex-start;
}

.list--check {
  @include list--check;

  &.list__item {
    align-items: flex-start;
    flex-basis: 100%;
    text-align: left;
    display: grid;
  }
}

.intro {
  &__image {
    object-fit: cover;
    max-width: 60%;

    @media (min-width: $size-lg) {
      transform: translateY(-2rem);
      max-width: 100%;
    }
  }
}

.columns-override {
  margin-left: unset; 
  margin-right: unset;
}
.column-override {
  padding-left: unset; 
  padding-right: unset;
}
</style>
